import ModifierGroupMultiChoiceTemplateServiceInterface
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-multi-choice/styles/ModifierGroupMultiChoiceTemplateServiceInterface'
import ModifierGroupMultiChoiceTemplateServiceStyleOne
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-multi-choice/styles/ModifierGroupMultiChoiceTemplateServiceStyleOne'
import ModifierGroupMultiChoiceTemplateServiceStyleTwo
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-multi-choice/styles/ModifierGroupMultiChoiceTemplateServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getModifierGroupMultiChoiceTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): ModifierGroupMultiChoiceTemplateServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new ModifierGroupMultiChoiceTemplateServiceStyleTwo(muiTheme)
    default:
      return new ModifierGroupMultiChoiceTemplateServiceStyleOne(muiTheme)
  }
}

export default getModifierGroupMultiChoiceTemplateService
