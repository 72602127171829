import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import ProductDetailsFooterTemplateServiceInterface
  from '~/src/components/products/product-details/product-details-footer/styles/ProductDetailsFooterTemplateServiceInterface'
import ProductDetailsFooterTemplateServiceStyleOne
  from '~/src/components/products/product-details/product-details-footer/styles/ProductDetailsFooterTemplateServiceStyleOne'
import ProductDetailsFooterTemplateServiceStyleTwo
  from '~/src/components/products/product-details/product-details-footer/styles/ProductDetailsFooterTemplateServiceStyleTwo'

const getProductDetailsFooterTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): ProductDetailsFooterTemplateServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new ProductDetailsFooterTemplateServiceStyleTwo(muiTheme)
    default:
      return new ProductDetailsFooterTemplateServiceStyleOne(muiTheme)
  }
}

export default getProductDetailsFooterTemplateService
