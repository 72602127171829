import SingleChoiceModifierTemplateOneService
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-single-choice/single-choice-modifier/styles/SingleChoiceModifierTemplateOneService'
import SingleChoiceModifierTemplateServiceInterface
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-single-choice/single-choice-modifier/styles/SingleChoiceModifierTemplateServiceInterface'
import SingleChoiceModifierTemplateTwoService
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-single-choice/single-choice-modifier/styles/SingleChoiceModifierTemplateTwoService'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getSingleChoiceModifierTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): SingleChoiceModifierTemplateServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new SingleChoiceModifierTemplateTwoService(muiTheme)
    default:
      return new SingleChoiceModifierTemplateOneService(muiTheme)
  }
}

export default getSingleChoiceModifierTemplateService
