import getProductDetailsTemplateService
  from '~/src/components/products/product-details/styles/getProductDetailsTemplateService'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const useProductDetailsTemplateService = () => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('product')

  return getProductDetailsTemplateService(pageStyle, muiTheme)
}

export default useProductDetailsTemplateService
