import Title from '~/src/components/mui-wrappers/Title'
import { useEoValue } from '~/src/hooks/useEoState'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import { Product } from '@eo-storefronts/eo-core'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

interface Props {
  product: Product,
}

const ProductTitle = ({ product }: Props) => {
  const lang = useEoValue(LANGUAGE_STATE)
  const muiTheme = useCustomMuiTheme()

  return (
    <Title
      className='eo-ellipsis'
      sx={{
        fontSize: '1.5em',
        maxWidth: '90%',
        [muiTheme.breakpoints.down('md')]: {
          whiteSpace: 'nowrap'
        }
      }}
    >
      {product.name[lang]}
    </Title>
  )
}

export default ProductTitle
