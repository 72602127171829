import { Alert } from '@mui/material'
import { Product } from '@eo-storefronts/eo-core'
import { useTranslations } from '~/src/hooks/useTranslations'
import useProductMaxReached from '~/src/hooks/products/useProductMaxReached'

interface Props {
  product: Product,
}

const ProductMaxQuantityAlert = ({ product }: Props) => {
  const isMaxReached = useProductMaxReached(product)
  const { t } = useTranslations()

  if (!isMaxReached) {
    return null
  }

  return <Alert severity='warning'>{t('cart.errors.max_product_quantity_reached')}</Alert>
}

export default ProductMaxQuantityAlert
