import MultiChoiceModifierTemplateServiceInterface
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-multi-choice/multi-choice-modifier/styles/MultiChoiceModifierTemplateServiceInterface'
import MultiChoiceModifierTemplateServiceStyleOne
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-multi-choice/multi-choice-modifier/styles/MultiChoiceModifierTemplateServiceStyleOne'
import MultiChoiceModifierTemplateServiceStyleTwo
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-multi-choice/multi-choice-modifier/styles/MultiChoiceModifierTemplateServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getMultiChoiceModifierTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): MultiChoiceModifierTemplateServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new MultiChoiceModifierTemplateServiceStyleTwo(muiTheme)
    default:
      return new MultiChoiceModifierTemplateServiceStyleOne(muiTheme)
  }
}

export default getMultiChoiceModifierTemplateService
