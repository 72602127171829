import { SxProps } from '@mui/material'
import ModifierGroupMultiChoiceTemplateServiceInterface
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-multi-choice/styles/ModifierGroupMultiChoiceTemplateServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class ModifierGroupMultiChoiceTemplateServiceStyleOne extends TemplateServiceStyleBase implements ModifierGroupMultiChoiceTemplateServiceInterface {
  public getContainerSx(): SxProps {
    return {
      mb: 2,
      boxShadow: this.muiTheme.elevation,
      backgroundColor: 'content.main',
      color: 'content.contrastText'
    }
  }

  public getModifiersBoxSx(): SxProps {
    return {
      px: 1,
      width: '100%'
    }
  }
}
