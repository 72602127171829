import getMultiChoiceModifierTemplateService
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-multi-choice/multi-choice-modifier/styles/getMultiChoiceModifierTemplateService'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetComponentStyle from '~/src/hooks/layout/useGetComponentStyle'

const useMultiChoiceModifierTemplateService = () => {
  const muiTheme = useCustomMuiTheme()
  const componentStyle = useGetComponentStyle('modifiers')

  return getMultiChoiceModifierTemplateService(componentStyle, muiTheme)
}

export default useMultiChoiceModifierTemplateService
