import { Product } from '@eo-storefronts/eo-core'
import { useEffect } from 'react'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import ModifierGroupService from '~/src/services/ModifierGroupService'
import { CHECKOUT_TIME_STATE } from '~/src/stores/checkout'
import { CART_MODIFIER_GROUP_STATE } from '~/src/stores/cart'
import { MODIFIER_GROUPS_STATE } from '~/src/stores/modifier-groups'
import { MODIFIERS_STATE } from '~/src/stores/modifiers'

const usePreselectModifiers = (product: Product): void => {
  const modifierGroups = useEoValue(MODIFIER_GROUPS_STATE)
  const modifiers = useEoValue(MODIFIERS_STATE)
  const setCartModifierGroups = useSetEoState(CART_MODIFIER_GROUP_STATE)
  const checkoutTime = useEoValue(CHECKOUT_TIME_STATE)
  const service = new ModifierGroupService(modifierGroups, modifiers)

  useEffect(() => {
    const cartModifierGroups = service.preselectModifiersForCartState(product.modifierGroups, checkoutTime)

    setCartModifierGroups(cartModifierGroups)
  }, [])
}

export default usePreselectModifiers
