import { Typography } from '@mui/material'
import Chip from '~/src/components/mui-wrappers/styled/chip'
import Price from '~/src/components/Price'

interface Props {
  price: number,
  asChip: boolean,
}

const ModifierPrice = ({ price, asChip }: Props) => {
  if (asChip) {
    return (
      <Chip
        color='primary'
        size='small'
        label={<Price price={price} displayZeroAmount={false} />}
      />
    )
  }

  return (
    <Typography variant='caption'>
      <Price price={price} displayZeroAmount={false} />
    </Typography>
  )
}

export default ModifierPrice
