import ModifierGroupTitleTemplateServiceInterface
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-title/styles/ModifierGroupTitleTemplateServiceInterface'
import ModifierGroupTitleTemplateServiceStyleOne
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-title/styles/ModifierGroupTitleTemplateServiceStyleOne'
import ModifierGroupTitleTemplateServiceStyleTwo
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-title/styles/ModifierGroupTitleTemplateServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const geModifierGroupTitleTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): ModifierGroupTitleTemplateServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new ModifierGroupTitleTemplateServiceStyleTwo(muiTheme)
    default:
      return new ModifierGroupTitleTemplateServiceStyleOne(muiTheme)
  }
}

export default geModifierGroupTitleTemplateService
