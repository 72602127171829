import getProductDetailsFooterTemplateService
  from '~/src/components/products/product-details/product-details-footer/styles/getProductDetailsFooterTemplateService'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const useProductDetailsFooterTemplateService = () => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('product')

  return getProductDetailsFooterTemplateService(pageStyle, muiTheme)
}

export default useProductDetailsFooterTemplateService
