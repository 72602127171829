import { ModifierGroup } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import ModifierGroupTitle from '~/src/components/products/modifier-groups/modifier-group/modifier-group-title'
import SingleChoiceModifiersContainer
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-single-choice/single-choice-modifiers-container'
import useModifierGroupSingleChoiceTemplateService
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-single-choice/styles/useModifierGroupSingleChoiceTemplateService'
import { CartModifierGroupStateInterface } from '~/src/stores/cart'

interface Props {
  group: ModifierGroup,
  cartModifierGroups: CartModifierGroupStateInterface[],
  onChange(cartModifierGroups: CartModifierGroupStateInterface[]): void,
}

const ModifierGroupSingleChoice = ({ group, cartModifierGroups, onChange }: Props) => {
  const styles = useModifierGroupSingleChoiceTemplateService()

  return (
    <Box sx={styles.getContainerSx()}>
      <ModifierGroupTitle
        group={group}
        showMinSelectionError={group.isRequired}
      />

      <Box
        className='eo-hide-scrollbar'
        sx={styles.getModifiersContainerSx()}
      >
        <SingleChoiceModifiersContainer
          group={group}
          cartModifierGroups={cartModifierGroups}
          onChange={onChange}
        />
      </Box>
    </Box>
  )
}

export default ModifierGroupSingleChoice
