import { Modifier } from '@eo-storefronts/eo-core'
import { Box, FormControl, FormControlLabel, Radio } from '@mui/material'
import useSingleChoiceModifierTemplateService
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-single-choice/single-choice-modifier/styles/useSingleChoiceModifierTemplateService'
import ModifierLabel from '~/src/components/products/modifier-groups/modifier-group/modifier/modifier-label'
import useModifiersService from '~/src/hooks/products/modifier-groups/modifiers/useModifiersService'
import { CartModifierGroupStateInterface, CartModifierStateInterface } from '~/src/stores/cart'

interface Props {
  modifier: Modifier,
  groupId: string,
  selected: boolean,
  cartModifierGroups: CartModifierGroupStateInterface[],
  onChange(cartModifierGroups: CartModifierGroupStateInterface[]): void,
}

const SingleChoiceModifier = ({ modifier, groupId, selected, cartModifierGroups, onChange }: Props) => {
  const modifierGroupsInCart = JSON.parse(JSON.stringify(cartModifierGroups))
  const { isModifierAvailable }  = useModifiersService(modifier)
  const available = isModifierAvailable()
  const styles = useSingleChoiceModifierTemplateService()

  const subCartModifierGroupModifiers = modifierGroupsInCart
    .find(
      (cartModifierGroup: CartModifierGroupStateInterface) => cartModifierGroup.id === groupId
    )?.modifiers

  const cartModifier = (subCartModifierGroupModifiers || [])
    .find((cartModifier: CartModifierStateInterface) => cartModifier.id === modifier.id)

  const handleOnChange = (subCartModifierGroups: CartModifierGroupStateInterface[]) => {
    if (modifier.ingredientType === 'main') {
      return
    }

    const groupIndex = modifierGroupsInCart.findIndex((cartModifierGroup: CartModifierGroupStateInterface) => cartModifierGroup.id === groupId)

    if (groupIndex === -1) {
      return
    }

    const modifierIndex = modifierGroupsInCart[groupIndex].modifiers.findIndex((cartModifier: CartModifierStateInterface) => cartModifier.id === modifier.id)

    if (modifierIndex === -1) {
      return
    }

    modifierGroupsInCart[groupIndex].modifiers[modifierIndex].modifierGroups = subCartModifierGroups
    onChange(modifierGroupsInCart)
  }

  return (
    <Box sx={styles.getContainerSx(available, selected)}>
      <FormControl sx={styles.getFormControlSx()}>
        <FormControlLabel
          sx={styles.getFormControlLabelSx(available, selected)}
          control={<Radio
            sx={styles.getRadioSx()}
            value={modifier.id}
            disabled={!available}
          />}
          label={(
            <ModifierLabel
              modifier={modifier}
              cartModifierGroups={cartModifier?.modifierGroups || []}
              selected={selected}
              onChange={handleOnChange}
            />
          )}
        />
      </FormControl>
    </Box>
  )
}

export default SingleChoiceModifier
