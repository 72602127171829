import { Modifier, ModifierGroup } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import MultiChoiceModifier
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-multi-choice/multi-choice-modifier'
import ModifierGroupTitle from '~/src/components/products/modifier-groups/modifier-group/modifier-group-title'
import useModifierGroupMultiChoiceTemplateService
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-multi-choice/styles/useModifierGroupMultiChoiceTemplateService'
import useCartModifierGroupsStateService from '~/src/hooks/products/modifier-groups/useCartModifierGroupsStateService'
import { CartModifierGroupStateInterface, CartModifierStateInterface } from '~/src/stores/cart'

interface Props {
  group: ModifierGroup,
  cartModifierGroups: CartModifierGroupStateInterface[],
  onChange(cartModifierGroups: CartModifierGroupStateInterface[]): void,
}

const ModifierGroupMultiChoice = ({ group, cartModifierGroups, onChange }: Props) => {
  const styles = useModifierGroupMultiChoiceTemplateService()
  const {
    isGroupMinSelectionReached,
    isGroupMaxSelectionReached,
    isGroupModifierMaxSelectionReached,
    addModifierToGroup,
    removeModifierFromGroup
  } = useCartModifierGroupsStateService()
  const disabled = isGroupMaxSelectionReached(cartModifierGroups, group)

  const subCartModifierGroupModifiers = cartModifierGroups
    .find((cartModifierGroup: CartModifierGroupStateInterface) => cartModifierGroup.id === group.id)?.modifiers

  const handleOnModifierUpdate = (
    modifier: Modifier,
    amount: number,
    selected: boolean
  ): void => {
    if (!selected) {
      onChange(removeModifierFromGroup(cartModifierGroups, group, modifier.id))
      return
    }

    onChange(addModifierToGroup(cartModifierGroups, group, modifier, amount))
  }

  return (
    <Box sx={styles.getContainerSx()}>
      <ModifierGroupTitle
        group={group}
        showMinSelectionError={!isGroupMinSelectionReached(cartModifierGroups, group)}
      />

      <Box
        className='eo-hide-scrollbar'
        sx={styles.getModifiersBoxSx()}
      >
        {group.modifiers.map((modifierId: string) => {
          const cartModifier = (subCartModifierGroupModifiers || []).find((cartModifier: CartModifierStateInterface) => cartModifier.id === modifierId)

          return (
            <MultiChoiceModifier
              key={modifierId}
              modifierId={modifierId}
              cartModifierGroups={cartModifier?.modifierGroups || []}
              canBeSelectedMultiTimes={group.multipleChoiceMultiselectMax === null || group.multipleChoiceMultiselectMax > 1}
              onChange={onChange}
              disabled={disabled || isGroupModifierMaxSelectionReached(cartModifierGroups, group, modifierId)}
              onUpdate={handleOnModifierUpdate}
            />
          )
        })}
      </Box>
    </Box>
  )
}

export default ModifierGroupMultiChoice
