import { ModifierGroup, Product } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import ModifierGroups from '~/src/components/products/modifier-groups'
import usePreselectModifiers from '~/src/hooks/products/usePreselectModifiers'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useEoState, useEoValue } from '~/src/hooks/useEoState'
import { CART_MODIFIER_GROUP_STATE, CartModifierGroupStateInterface } from '~/src/stores/cart'
import { PRODUCT_MODIFIER_GROUP_SELECTOR } from '~/src/stores/modifier-groups'
import ProductDetailsComment from '~/src/components/products/product-details/product-details-comment'

interface Props {
  product: Product,
}

const ProductDetailsInfo = ({ product }: Props) => {
  const muiTheme = useCustomMuiTheme()
  const [ cartModifierGroups, setCartModifierGroups ] = useEoState<CartModifierGroupStateInterface[]>(CART_MODIFIER_GROUP_STATE)
  const productModifierGroup = useEoValue<ModifierGroup[]>(PRODUCT_MODIFIER_GROUP_SELECTOR(product.id))

  usePreselectModifiers(product)

  const handleOnModifierGroupsChange = (cartModifierGroups: CartModifierGroupStateInterface[]) => {
    setCartModifierGroups(cartModifierGroups)
  }

  return (
    <Box
      sx={{
        px: 2,
        [muiTheme.breakpoints.up('md')]: {
          maxHeight: '50vh',
          overflowY: 'auto'
        },
        [muiTheme.breakpoints.down('md')]: {
          mb: 3,
          width: '100%'
        }
      }}
    >
      <ModifierGroups
        groups={productModifierGroup}
        cartModifierGroups={cartModifierGroups}
        onChange={handleOnModifierGroupsChange}
      />
      <ProductDetailsComment/>
    </Box>
  )
}

export default ProductDetailsInfo
