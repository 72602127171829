import { SxProps } from '@mui/material'
import ModifierGroupSingleChoiceTemplateServiceInterface
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-single-choice/styles/ModifierGroupSingleChoiceTemplateServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class ModifierGroupSingleChoiceTemplateTwoService extends TemplateServiceStyleBase implements ModifierGroupSingleChoiceTemplateServiceInterface {
  public getContainerSx(): SxProps {
    return {
      mb: 2
    }
  }

  public getModifiersContainerSx(): SxProps {
    return {
      display: 'flex',
      maxWidth: '100%',
      flexWrap: 'wrap',
      [this.muiTheme.breakpoints.down('md')]: {
        display: 'block',
        overflow: 'auto'
      }
    }
  }
}
