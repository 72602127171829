import { Product } from '@eo-storefronts/eo-core'
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { CART_ITEM_WITH_PRODUCT_SELECTOR, CART_OF_FIRM_SELECTOR } from '~/src/stores/cart'
import { convertProductUnitKgInGr } from '~/src/services/ProductService'
import { CartProduct } from '~/src/types/CartProduct'

interface ReturnsType {
  quantity: number,
  setQuantity: Dispatch<SetStateAction<number>>,
  interval: number,
  minQuantity: number,
  maxQuantity: number,
}

const useProductDetailQuantity = (product: Product): ReturnsType => {
  const cart = useEoValue<CartProduct[]>(CART_OF_FIRM_SELECTOR)
  const cartProduct = useEoValue<CartProduct | undefined>(CART_ITEM_WITH_PRODUCT_SELECTOR(product.id))
  const [ maxQuantity, setMaxQuantity ] = useState<number>(0)
  const [ interval, setInterval ] = useState<number>(0)
  const [ minQuantity, setMinQuantity ] = useState<number>(0)
  const [ quantity, setQuantity ] = useState<number>(0)

  const _calculate = useCallback(() => {
    // eslint-disable-next-line prefer-const
    let { max, min, unitInterval } = convertProductUnitKgInGr(product)

    // If the product is not in the cart and there is a min then the starting quantity is equal to the min unless
    // equals unit_interval
    let quantity: number = (!cartProduct && min) ? min : unitInterval

    if (cartProduct) {
      // If the product is in the cart then quantity min to order is the unit interval
      min = unitInterval

      // If there is a max quantity I make a calculation
      if (max) {
        // The max is not the default one anymore but it's max minus the quantity already in the cart
        max = max - cartProduct.quantity

        // After calculation if there is a min and that max is smaller than min then min equals the max
        if (min && max < min) {
          min = max
        }

        // Same with the quantity if after calculation the max is smaller than unit interval
        // the starting quantity equals the max
        if (max < unitInterval) {
          quantity = max
        }
      }
    }

    setQuantity(quantity)
    setMaxQuantity(max)
    setInterval(unitInterval)
    setMinQuantity(min)
  }, [ cart ])

  useEffect(() => {
    _calculate()
  }, [ cart ])

  return { quantity, setQuantity, interval, minQuantity, maxQuantity }
}

export default useProductDetailQuantity
