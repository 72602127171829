import getModifierLabelTemplateService
  from '~/src/components/products/modifier-groups/modifier-group/modifier/modifier-label/styles/getModifierLabelTemplateService'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetComponentStyle from '~/src/hooks/layout/useGetComponentStyle'

const useModifierLabelTemplateService = () => {
  const muiTheme = useCustomMuiTheme()
  const componentStyle = useGetComponentStyle('modifiers')

  return getModifierLabelTemplateService(componentStyle, muiTheme)
}

export default useModifierLabelTemplateService
