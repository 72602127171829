import { Firm } from '@eo-storefronts/eo-core'
import { ChangeEvent } from 'react'
import { useEoState, useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { PRODUCT_COMMENT_STATE } from '~/src/stores/product-comment'
import TextField from '~/src/components/mui-wrappers/styled/text-field'

const ProductDetailsComment = () => {
  const firm = useEoValue<Firm | null>(FIRM_SELECTOR)
  const { t } = useTranslations()
  const [ productComment, setProductComment ] = useEoState(PRODUCT_COMMENT_STATE)

  const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setProductComment(event.target.value)
  }

  if (!firm?.settings.theme.show_comments_step_product_basket) {
    return null
  }

  return (
    <TextField
      fullWidth
      color='container'
      variant='outlined'
      size='small'
      label={t('checkout.give_us_more_info')}
      multiline
      rows={4}
      value={productComment}
      onChange={handleOnChange}
    />
  )
}

export default ProductDetailsComment
