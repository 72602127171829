import EditIcon from '@mui/icons-material/Edit'
import { IconButton } from '@mui/material'
import { useContext } from 'react'
import ResponsiveDialogContext, {
  ResponsiveDialogContextInterface
} from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'

interface Props {
  error: boolean,
}

const ModifierEditActivator = ({ error }: Props) => {
  const responsiveDialogContext = useContext<ResponsiveDialogContextInterface>(ResponsiveDialogContext)

  return (
    <IconButton onClick={responsiveDialogContext.toggle}>
      <EditIcon color={error ? 'error' : 'action'} fontSize='small' />
    </IconButton>
  )
}

export default ModifierEditActivator
