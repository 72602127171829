import { Modifier, ModifierGroup as ModifierGroupType } from '@eo-storefronts/eo-core'
import { Box, dialogActionsClasses, dialogContentClasses } from '@mui/material'
import ResponsiveDialog from '~/src/components/mui-wrappers/responsive-dialog'
import ModifierGroup from '~/src/components/products/modifier-groups/modifier-group'
import ModifierEditActivator
  from '~/src/components/products/modifier-groups/modifier-group/modifier/modifier-edit-activator'
import ModifierEditSave from '~/src/components/products/modifier-groups/modifier-group/modifier/modifier-edit-save'
import useCartModifierGroupsStateService from '~/src/hooks/products/modifier-groups/useCartModifierGroupsStateService'
import { useEoValue } from '~/src/hooks/useEoState'
import { CartModifierGroupStateInterface } from '~/src/stores/cart'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import { MODIFIER_GROUPS_STATE } from '~/src/stores/modifier-groups'

interface Props {
  modifier: Modifier,
  cartModifierGroups: CartModifierGroupStateInterface[],
  onChange(cartModifierGroups: CartModifierGroupStateInterface[]): void,
}

const ModifierEditButton = ({ modifier, cartModifierGroups, onChange }: Props) => {
  const modifierGroups = useEoValue<Record<string, ModifierGroupType>>(MODIFIER_GROUPS_STATE)
  const lang = useEoValue(LANGUAGE_STATE)
  const { validateGroups } = useCartModifierGroupsStateService()

  const handleOnSubModifierGroupChange = (cartModifierGroups: CartModifierGroupStateInterface[]) => {
    onChange(cartModifierGroups)
  }

  if (!modifier.modifierGroups?.length) {
    return null
  }

  return (
    <Box sx={{
      position: 'absolute',
      bottom: 0,
      right: '10px'
    }}>
      <ResponsiveDialog
        Title={<>{modifier.name[lang]}</>}
        Actions={<ModifierEditSave />}
        keepMounted={true}
        sx={{
          zIndex: 1301,
          [`.${dialogContentClasses.root}`]: {
            backgroundColor: 'secondary.main',
            color: 'secondary.contrastText',
            maxHeight: '75vh',
            pt: 2
          },
          [`.${dialogActionsClasses.root}`]: {
            pb: 1
          }
        }}
        activator={(
          <ModifierEditActivator
            error={!validateGroups(cartModifierGroups, modifier.modifierGroups || [])}
          />
        )}
      >
        {modifier.modifierGroups?.map((groupId: string) => (
          <ModifierGroup
            key={groupId}
            group={modifierGroups[groupId]}
            cartModifierGroups={cartModifierGroups}
            onChange={handleOnSubModifierGroupChange}
          />
        ))}
      </ResponsiveDialog>
    </Box>
  )
}

export default ModifierEditButton
