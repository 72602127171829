import { Product } from '@eo-storefronts/eo-core'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import { Box, SxProps, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import PrimaryButton from '~/src/components/mui-wrappers/buttons/PrimaryButton'
import Price from '~/src/components/Price'
import { Constants } from '~/src/helpers/constants'
import { useAddProductToCart } from '~/src/hooks/cart/useAddProductToCart'
import { useCalculatePrice } from '~/src/hooks/products/useCalculatePrice'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { LAYOUT_STATE } from '~/src/stores/layout'
import { PRODUCT_IS_REWARD_FOR_LOYALTY_SELECTOR } from '~/src/stores/product'
import { getProductPrice } from '~/src/services/ProductService'
import ResponsiveDialogContext, {
  ResponsiveDialogContextInterface
} from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'
import { COUPON_STATE } from '~/src/stores/checkout'

interface Props {
  product: Product,
  quantity: number,
  disabled: boolean,
  sx?: SxProps,
}

const ProductDetailsAddToCart = ({ product, quantity, disabled, sx = {} }: Props) => {
  const muiTheme = useCustomMuiTheme()
  const { addToCart } = useAddProductToCart()
  const { calculate: calculatePrice } = useCalculatePrice()
  const { t } = useTranslations()
  const coupon = useEoValue(COUPON_STATE)
  const productIsRewardForLoyalty = useEoValue(PRODUCT_IS_REWARD_FOR_LOYALTY_SELECTOR(product.id))
  const { isMobile } = useEoValue(LAYOUT_STATE)
  const [ price, setPrice ] = useState(product.price)
  const [ loading, setLoading ] = useState(false)
  const responsiveDialogContext = useContext<ResponsiveDialogContextInterface>(ResponsiveDialogContext)

  const isCoupon = coupon?.product?.id === product.id || coupon?.category?.id === product.categoryId

  const handleAddToCart = () => {
    setLoading(true)

    addToCart(product, quantity)

    setTimeout(() => {
      responsiveDialogContext.toggleClose && responsiveDialogContext.toggleClose({ addedToCart: true })
    }, Constants.DEBOUNCE_TIME)
  }

  useEffect(() => {
    const price: number = getProductPrice(product)

    let newPrice = calculatePrice(price, quantity)

    if (productIsRewardForLoyalty || isCoupon) {
      newPrice -= product.price
    }

    setPrice(newPrice)
  }, [ productIsRewardForLoyalty, calculatePrice, quantity ])

  return (
    <PrimaryButton
      id='product_details__add_to_cart_btn'
      disabled={disabled || loading}
      loading={loading}
      fullWidth
      onClick={handleAddToCart}
      sx={sx}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          p: 1,
          [muiTheme.breakpoints.only('xs')]: {
            p: 1 / 2
          }
        }}
      >
        {isMobile && <ShoppingCartIcon />}
        <Typography variant='body2'>{t('cart.add_to_cart')}</Typography>
        <Typography
          className='price'
          variant='body2'
        >
          <Price price={price} />
        </Typography>
      </Box>
    </PrimaryButton>
  )
}

export default ProductDetailsAddToCart
