import { SxProps } from '@mui/material'
import ModifierGroupMultiChoiceTemplateServiceInterface
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-multi-choice/styles/ModifierGroupMultiChoiceTemplateServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class ModifierGroupMultiChoiceTemplateServiceStyleTwo extends TemplateServiceStyleBase implements ModifierGroupMultiChoiceTemplateServiceInterface {
  public getContainerSx(): SxProps {
    return { }
  }

  public getModifiersBoxSx(): SxProps {
    return {
      display: 'grid',
      gridTemplateColumns: 'repeat(5, 1fr)',
      justifyItems: 'stretch',
      gap: 1,
      maxWidth: '100%',
      mt: 1,
      [this.muiTheme.breakpoints.down('md')]: {
        display: 'flex',
        overflow: 'scroll'
      }
    }
  }
}
