import getSingleChoiceModifierTemplateService
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-single-choice/single-choice-modifier/styles/getSingleChoiceModifierTemplateService'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetComponentStyle from '~/src/hooks/layout/useGetComponentStyle'

const useSingleChoiceModifierTemplateService = () => {
  const muiTheme = useCustomMuiTheme()
  const componentStyle = useGetComponentStyle('modifiers')

  return getSingleChoiceModifierTemplateService(componentStyle, muiTheme)
}

export default useSingleChoiceModifierTemplateService
