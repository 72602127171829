import { checkboxClasses, SxProps } from '@mui/material'
import MultiChoiceModifierTemplateServiceInterface
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-multi-choice/multi-choice-modifier/styles/MultiChoiceModifierTemplateServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class MultiChoiceModifierTemplateServiceStyleTwo extends TemplateServiceStyleBase implements MultiChoiceModifierTemplateServiceInterface {
  public getContainerSx(disabled: boolean): SxProps {
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      filter: disabled ? 'opacity(.5) grayscale(1)' : undefined
    }
  }

  public getModifierSx(): SxProps {
    return {
      display: 'grid',
      gap: 1 / 2,
      gridTemplateRows: 'auto max-content',
      gridTemplateColumns: '1fr',
      gridTemplateAreas: '"label" "q-selector"',
      width: '100%',
      height: '100%',
      [this.muiTheme.breakpoints.down('md')]: {
        gridTemplateColumns: 'max-content'
      }
    }
  }

  public getQuantitySelectorSx(quantity: number): SxProps {
    return {
      gridArea: 'q-selector',
      mx: 'auto',
      visibility: !quantity ? 'hidden' : 'visible'
    }
  }

  public getModifierLabelSx(selected: boolean): SxProps {
    return {
      gridArea: 'label',
      display: 'flex',
      p: 1.5,
      cursor: 'pointer',
      borderRadius: this.muiTheme.shape.borderRadius,
      flexDirection: 'column',
      backgroundColor: 'tab.main',
      color: !selected ? 'tab.contrastText' : 'primary.main',
      borderColor: !selected ? 'tab.main' : 'primary.main',
      borderWidth: '1px',
      borderStyle: 'solid',
      [`.${checkboxClasses.root}`]: {
        display: 'none'
      }
    }
  }

  public displayPriceAsChip(): boolean {
    return false
  }
}
