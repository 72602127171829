import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import ProductDetailsTemplateServiceInterface
  from '~/src/components/products/product-details/styles/ProductDetailsTemplateServiceInterface'
import ProductDetailsTemplateServiceStyleOne
  from '~/src/components/products/product-details/styles/ProductDetailsTemplateServiceStyleOne'
import ProductDetailsTemplateServiceStyleTwo
  from '~/src/components/products/product-details/styles/ProductDetailsTemplateServiceStyleTwo'

const getProductDetailsTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): ProductDetailsTemplateServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new ProductDetailsTemplateServiceStyleTwo(muiTheme)
    default:
      return new ProductDetailsTemplateServiceStyleOne(muiTheme)
  }
}

export default getProductDetailsTemplateService
