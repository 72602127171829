import SingleChoiceModifiersContainerTemplateServiceInterface
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-single-choice/single-choice-modifiers-container/styles/SingleChoiceModifiersContainerTemplateServiceInterface'
import SingleChoiceModifiersContainerTemplateServiceStyleOne
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-single-choice/single-choice-modifiers-container/styles/SingleChoiceModifiersContainerTemplateServiceStyleOne'
import SingleChoiceModifiersContainerTemplateServiceStyleTwo
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-single-choice/single-choice-modifiers-container/styles/SingleChoiceModifiersContainerTemplateServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getSingleChoiceModifiersContainerTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): SingleChoiceModifiersContainerTemplateServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new SingleChoiceModifiersContainerTemplateServiceStyleTwo(muiTheme)
    default:
      return new SingleChoiceModifiersContainerTemplateServiceStyleOne(muiTheme)
  }
}

export default getSingleChoiceModifiersContainerTemplateService
