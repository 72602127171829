import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import getModifierGroupMultiChoiceTemplateService from './getModifierGroupMultiChoiceTemplateService'
import useGetComponentStyle from '~/src/hooks/layout/useGetComponentStyle'

const useModifierGroupMultiChoiceTemplateService = () => {
  const muiTheme = useCustomMuiTheme()
  const componentStyle = useGetComponentStyle('modifiers')

  return getModifierGroupMultiChoiceTemplateService(componentStyle, muiTheme)
}

export default useModifierGroupMultiChoiceTemplateService
