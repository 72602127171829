import { Modifier } from '@eo-storefronts/eo-core'
import { Box, Typography } from '@mui/material'
import ModifierEditButton from '~/src/components/products/modifier-groups/modifier-group/modifier/modifier-edit-button'
import useModifierLabelTemplateService
  from '~/src/components/products/modifier-groups/modifier-group/modifier/modifier-label/styles/useModifierLabelTemplateService'
import ModifierMedia from '~/src/components/products/modifier-groups/modifier-group/modifier/modifier-media'
import ModifierPrice from '~/src/components/products/modifier-groups/modifier-group/modifier/modifier-price'
import { useEoValue } from '~/src/hooks/useEoState'
import { CartModifierGroupStateInterface } from '~/src/stores/cart'
import { LANGUAGE_STATE } from '~/src/stores/lang'

interface Props {
  modifier: Modifier,
  cartModifierGroups: CartModifierGroupStateInterface[],
  selected: boolean,
  onChange(cartModifierGroups: CartModifierGroupStateInterface[]): void,
}

const ModifierLabel = ({ modifier, cartModifierGroups, selected, onChange }: Props) => {
  const styles = useModifierLabelTemplateService()
  const lang = useEoValue(LANGUAGE_STATE)

  return (
    <Box sx={styles.getContainerSx()}>
      {selected && <ModifierEditButton
        modifier={modifier}
        cartModifierGroups={cartModifierGroups}
        onChange={onChange}
      />}
      <ModifierMedia modifier={modifier} />
      <Typography variant='body2'>{modifier.name[lang]}</Typography>
      {modifier.price > 0 && (
        <Box sx={styles.getPriceContainerSx()}>
          <ModifierPrice
            price={modifier.price}
            asChip={styles.displayPriceAsChip()}
          />
        </Box>
      )}
    </Box>
  )
}

export default ModifierLabel
