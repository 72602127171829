import { useEoValue } from '~/src/hooks/useEoState'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import { Product } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import Image from '~/src/components/mui-wrappers/Image'
import useProductDetailsTemplateService
  from '~/src/components/products/product-details/styles/useProductDetailsTemplateService'
import { useState } from 'react'
import { SHOW_PRODUCT_DEFAULT_IMG_SELECTOR } from '~/src/stores/theme'

interface Props {
  product: Product,
}

const ProductDetailsImg = ({ product }: Props) => {
  const [ productImgIsVisible, setProductImgIsVisible ] = useState<boolean>(true)
  const styles = useProductDetailsTemplateService()
  const lang = useEoValue(LANGUAGE_STATE)
  const showDefaultImg = useEoValue(SHOW_PRODUCT_DEFAULT_IMG_SELECTOR)

  const _shouldDisplayWellsColor = (): boolean => {
    if (productImgIsVisible) {
      if (product.images[0]?.is_default) {
        return showDefaultImg
      }

      return true
    }

    return false
  }

  const _handleOnImageLoadedError = () => {
    setProductImgIsVisible(false)
  }

  return (
    <Box sx={styles.getImageSx()}>
      <Box
        sx={{
          backgroundColor: _shouldDisplayWellsColor() ? 'wells.main' : 'transparent',
          color: _shouldDisplayWellsColor() ? 'wells.contrastText' : 'transparent',
          height: '100%'
        }}
      >
        <Image
          onError={_handleOnImageLoadedError}
          src={product.images[0]?.detail}
          alt={product.name[lang] || ''}
          isDefault={product.images[0]?.is_default}
          sx={{
            width: '100%',
            height: 'inherit'
          }}
        />
      </Box>
    </Box>
  )
}

export default ProductDetailsImg
