import ModifierGroupSingleChoiceTemplateOneService
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-single-choice/styles/ModifierGroupSingleChoiceTemplateOneService'
import ModifierGroupSingleChoiceTemplateServiceInterface
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-single-choice/styles/ModifierGroupSingleChoiceTemplateServiceInterface'
import ModifierGroupSingleChoiceTemplateTwoService
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-single-choice/styles/ModifierGroupSingleChoiceTemplateTwoService'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getModifierGroupSingleChoiceTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): ModifierGroupSingleChoiceTemplateServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new ModifierGroupSingleChoiceTemplateTwoService(muiTheme)
    default:
      return new ModifierGroupSingleChoiceTemplateOneService(muiTheme)
  }
}

export default getModifierGroupSingleChoiceTemplateService
