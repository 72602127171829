import { Product } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import useProductDetailsFooterTemplateService
  from '~/src/components/products/product-details/product-details-footer/styles/useProductDetailsFooterTemplateService'
import ProductUnitPrice from '~/src/components/products/product-unit-price'
import ProductDetailsAddToCart from '~/src/components/products/ProductDetailsAddToCart'
import QuantitySelector from '~/src/components/products/QuantitySelector'
import { useAddToCartButtonStatus } from '~/src/hooks/cart/useAddToCartButtonStatus'
import useProductDetailQuantity from '~/src/hooks/products/useProductDetailQuantity'
import { CartProduct } from '~/src/types/CartProduct'
import { useEoValue } from '~/src/hooks/useEoState'
import { COUPON_STATE } from '~/src/stores/checkout'
import useLoyaltyService from '~/src/hooks/customer/useLoyaltyService'
import { CART_OF_FIRM_SELECTOR } from '~/src/stores/cart'

interface Props {
  product: Product,
}

const ProductDetailsFooter = ({ product }: Props) => {
  const coupon = useEoValue(COUPON_STATE)
  const cart = useEoValue(CART_OF_FIRM_SELECTOR)
  const loyaltyService = useLoyaltyService()
  const { isAddToCartButtonEnabled } = useAddToCartButtonStatus(product)
  const styles = useProductDetailsFooterTemplateService()
  const {
    quantity: count,
    setQuantity: setCount,
    interval,
    minQuantity,
    maxQuantity
  } = useProductDetailQuantity(product)

  const isCoupon = coupon?.product?.id === product.id || coupon?.category?.id === product.categoryId
  const isLoyalty = loyaltyService.isProductEligible(product) && !cart.find((cp: CartProduct) => cp.is_loyalty)

  return (
    <Box sx={styles.getContainerSx()}>
      <Box sx={styles.getQuantitySx()}>
        <QuantitySelector
          quantity={count}
          onUpdate={setCount}
          unitInterval={interval}
          unit={product.unit}
          min={minQuantity}
          max={maxQuantity}
          disabled={count === 0 || isCoupon || isLoyalty}
        />
      </Box>
      {styles.displayUnitProductPrice() && (
        <ProductUnitPrice
          product={product}
        />
      )}
      <Box sx={styles.getAddToCartSx()}>
        <ProductDetailsAddToCart
          product={product}
          quantity={count}
          disabled={!isAddToCartButtonEnabled() || count === 0}
        />
      </Box>
    </Box>
  )
}

export default ProductDetailsFooter
