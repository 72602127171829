import { formControlLabelClasses, radioClasses, SxProps } from '@mui/material'
import SingleChoiceModifierTemplateServiceInterface
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-single-choice/single-choice-modifier/styles/SingleChoiceModifierTemplateServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class SingleChoiceModifierTemplateOneService extends TemplateServiceStyleBase implements SingleChoiceModifierTemplateServiceInterface {
  public getContainerSx(available: boolean): SxProps {
    return {
      filter: !available ? 'grayscale(1)' : undefined
    }
  }

  public getFormControlSx(): SxProps {
    return {
      width: '100%',
      pl: 1.5
    }
  }

  public getFormControlLabelSx(): SxProps {
    return {
      display: 'flex',
      width: '100%',
      [`.${formControlLabelClasses.label}`]: {
        flexGrow: 1
      }
    }
  }

  public getRadioSx(): SxProps {
    return {
      color: 'inherit',
      [`&.${radioClasses.checked}`]: {
        color: 'inherit'
      }
    }
  }
}
