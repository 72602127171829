import { SxProps } from '@mui/material'
import ModifierLabelTemplateServiceInterface
  from '~/src/components/products/modifier-groups/modifier-group/modifier/modifier-label/styles/ModifierLabelTemplateServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class ModifierLabelTemplateServiceStyleOne extends TemplateServiceStyleBase implements ModifierLabelTemplateServiceInterface {
  public getContainerSx(): SxProps {
    return {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      gap: 2,
      cursor: 'pointer'
    }
  }

  public getPriceContainerSx(): SxProps {
    return {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'flex-end'
    }
  }

  public displayPriceAsChip(): boolean {
    return true
  }
}
