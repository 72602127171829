import { Modifier, ModifierGroup } from '@eo-storefronts/eo-core'
import { RadioGroup } from '@mui/material'
import { ChangeEvent, useEffect, useState } from 'react'
import SingleChoiceModifier
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-single-choice/single-choice-modifier'
import useSingleChoiceModifiersContainerTemplateService
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-single-choice/single-choice-modifiers-container/styles/useSingleChoiceModifiersContainerTemplateService'
import useCartModifierGroupsStateService from '~/src/hooks/products/modifier-groups/useCartModifierGroupsStateService'
import { useEoValue } from '~/src/hooks/useEoState'
import { CartModifierGroupStateInterface } from '~/src/stores/cart'
import { GROUP_MODIFIERS_SELECTOR, MODIFIERS_STATE } from '~/src/stores/modifiers'

interface Props {
  group: ModifierGroup,
  cartModifierGroups: CartModifierGroupStateInterface[],
  onChange(cartModifierGroups: CartModifierGroupStateInterface[]): void,
}

const SingleChoiceModifiersContainer = ({ group, cartModifierGroups, onChange }: Props) => {
  const styles = useSingleChoiceModifiersContainerTemplateService()
  const modifiers = useEoValue(MODIFIERS_STATE)
  const groupModifiers = useEoValue(GROUP_MODIFIERS_SELECTOR(group.id))
  const [ selectedModifierId, setSelectedModifierId ] = useState<string>('')
  const { setModifierToGroup } = useCartModifierGroupsStateService()

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>, value: string): void => {
    if (!modifiers[value]) {
      return
    }

    onChange(setModifierToGroup(cartModifierGroups, group, modifiers[value]))
    setSelectedModifierId(value)
  }

  useEffect(() => {
    const modifier = groupModifiers.find((modifier: Modifier) => modifier.isDefault)

    if (modifier) {
      setSelectedModifierId(modifier.id)
    }
  }, [])

  return (
    <RadioGroup
      name={`option-group-${group.id}`}
      value={selectedModifierId}
      onChange={handleOnChange}
      sx={styles.getContainerSx()}
    >
      {groupModifiers.map((modifier: Modifier) => (
        <SingleChoiceModifier
          key={modifier.id}
          groupId={group.id}
          modifier={modifier}
          cartModifierGroups={cartModifierGroups}
          onChange={onChange}
          selected={modifier.id === selectedModifierId}
        />
      ))}
    </RadioGroup>
  )
}

export default SingleChoiceModifiersContainer
