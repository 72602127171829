import getSingleChoiceModifiersContainerTemplateService
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-single-choice/single-choice-modifiers-container/styles/getSingleChoiceModifiersContainerTemplateService'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetComponentStyle from '~/src/hooks/layout/useGetComponentStyle'

const useSingleChoiceModifiersContainerTemplateService = () => {
  const muiTheme = useCustomMuiTheme()
  const componentStyle = useGetComponentStyle('modifiers')

  return getSingleChoiceModifiersContainerTemplateService(componentStyle, muiTheme)
}

export default useSingleChoiceModifiersContainerTemplateService
