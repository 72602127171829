import { Modifier, ModifierGroup } from '@eo-storefronts/eo-core'
import { useCallback } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import CartService from '~/src/services/CartService'
import { CART_MODIFIER_GROUP_STATE, CartModifierGroupStateInterface } from '~/src/stores/cart'
import { MODIFIER_GROUPS_STATE } from '~/src/stores/modifier-groups'
import { MODIFIERS_STATE } from '~/src/stores/modifiers'

interface ReturnsType {
  calculate(price: number, quantity: number): number,
}

const useCalculatePrice = (): ReturnsType => {
  const modifierGroups = useEoValue<Record<string, ModifierGroup>>(MODIFIER_GROUPS_STATE)
  const modifiers = useEoValue<Record<string, Modifier>>(MODIFIERS_STATE)
  const cartModifierGroups = useEoValue<CartModifierGroupStateInterface[]>(CART_MODIFIER_GROUP_STATE)
  const service = new CartService(
    modifierGroups,
    modifiers
  )

  const calculate = useCallback((
    price: number,
    quantity: number
  ): number => {
    return service
      .calculateCartProductPrice(price, quantity, cartModifierGroups)
  }, [ modifiers, modifierGroups, cartModifierGroups ])

  return { calculate }
}

export {
  useCalculatePrice
}
