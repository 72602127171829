import { LanguageType, ModifierGroup } from '@eo-storefronts/eo-core'
import { Box, Typography } from '@mui/material'
import Title from '~/src/components/mui-wrappers/Title'
import useModifierGroupTitleTemplateService
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-title/styles/useModifierGroupTitleTemplateService'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { LANGUAGE_STATE } from '~/src/stores/lang'

interface Props {
  group: ModifierGroup,
  showMinSelectionError: boolean,
}

const ModifierGroupTitle = ({ group, showMinSelectionError }: Props) => {
  const { t } = useTranslations()
  const styles = useModifierGroupTitleTemplateService()
  const lang = useEoValue<LanguageType|string>(LANGUAGE_STATE)

  return (
    <Box sx={styles.getContainerSx()}>
      <Title variant='body1'>{group.name[lang]}</Title>
      {
        showMinSelectionError && (
          <Typography
            variant='caption'
            color='error'
            sx={{ fontStyle: 'italic' }}
          >
            {t('global.min')}: {group.multipleChoiceMin ?? 1}
          </Typography>
        )}
    </Box>
  )
}

export default ModifierGroupTitle
