import getModifierMediaTemplateService
  from '~/src/components/products/modifier-groups/modifier-group/modifier/modifier-media/styles/getModifierMediaTemplateService'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetComponentStyle from '~/src/hooks/layout/useGetComponentStyle'

const useModifierMediaTemplateService = () => {
  const muiTheme = useCustomMuiTheme()
  const componentStyle = useGetComponentStyle('modifiers')

  return getModifierMediaTemplateService(componentStyle, muiTheme)
}

export default useModifierMediaTemplateService
