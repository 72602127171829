import { SxProps } from '@mui/material'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import ProductDetailsTemplateServiceInterface
  from '~/src/components/products/product-details/styles/ProductDetailsTemplateServiceInterface'

export default class ProductDetailsTemplateServiceStyleTwo extends TemplateServiceStyleBase implements ProductDetailsTemplateServiceInterface {
  public getCardElevation(): number {
    return 0
  }

  public getContainerSx(): SxProps {
    return {
      display: 'grid',
      position: 'relative',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridTemplateRows: 'repeat(2, max-content) auto',
      gridTemplateAreas: '"image info info" "_ info info" "footer info info"',
      gap: 2,
      p: 2,
      [this.muiTheme.breakpoints.only('xs')]: {
        gridTemplateColumns: '100%',
        gridTemplateRows: 'repeat(3, max-content)',
        gridTemplateAreas: '"image" "footer" "info"',
        minHeight: '100vh',
        gap: 0,
        p: 0,
        pb: 6
      }
    }
  }

  public getImageSx(): SxProps {
    return {
      gridArea: 'image',
      pt: 'var(--eo-safe-area-top)',
      [this.muiTheme.breakpoints.only('xs')]: {
        pb: 1
      }
    }
  }

  public getInfoSx(): SxProps {
    return {
      gridArea: 'info',
      width: '100%'
    }
  }

  public getFooterSx(): SxProps {
    return {
      gridArea: 'footer',
      alignSelf: 'flex-end'
    }
  }
}
