import { SxProps } from '@mui/material'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import ProductDetailsTemplateServiceInterface
  from '~/src/components/products/product-details/styles/ProductDetailsTemplateServiceInterface'

export default class ProductDetailsTemplateServiceStyleOne extends TemplateServiceStyleBase implements ProductDetailsTemplateServiceInterface {
  public getCardElevation(): number {
    return this.muiTheme.elevation
  }

  public getContainerSx(): SxProps {
    return {
      display: 'grid',
      position: 'relative',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridTemplateRows: 'repeat(2, max-content) auto',
      gridTemplateAreas: '"image info info" "_ info info" "footer footer footer"',
      gap: 2,
      [this.muiTheme.breakpoints.only('xs')]: {
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'repeat(3, auto)',
        gridTemplateAreas: '"image" "info" "footer"'
      }
    }
  }

  public getImageSx(): SxProps {
    return {
      gridArea: 'image',
      p: 2
    }
  }

  public getInfoSx(): SxProps {
    return {
      gridArea: 'info',
      p: 2
    }
  }

  public getFooterSx(offset: string): SxProps {
    return {
      gridArea: 'footer',
      position: 'sticky',
      zIndex: 2,
      bottom: `calc(${offset}px + var(--eo-safe-area-bottom))`
    }
  }
}
