import { SxProps } from '@mui/material'
import SingleChoiceModifiersContainerTemplateServiceInterface
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-single-choice/single-choice-modifiers-container/styles/SingleChoiceModifiersContainerTemplateServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class SingleChoiceModifiersContainerTemplateServiceStyleTwo extends TemplateServiceStyleBase implements SingleChoiceModifiersContainerTemplateServiceInterface {
  public getContainerSx(): SxProps {
    return {
      display: 'grid',
      gridTemplateColumns: 'repeat(5, 1fr)',
      justifyItems: 'stretch',
      gap: 1,
      py: 1,
      px: 2,
      [this.muiTheme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap'
      }
    }
  }
}
