import { SxProps } from '@mui/material'
import ModifierLabelTemplateServiceInterface
  from '~/src/components/products/modifier-groups/modifier-group/modifier/modifier-label/styles/ModifierLabelTemplateServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class ModifierLabelTemplateServiceStyleTwo extends TemplateServiceStyleBase implements ModifierLabelTemplateServiceInterface {
  public getContainerSx(): SxProps {
    return {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      height: '100%',
      gap: 1,
      cursor: 'pointer'
    }
  }

  public getPriceContainerSx(): SxProps {
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      opacity: '.6'
    }
  }

  public displayPriceAsChip(): boolean {
    return false
  }
}
