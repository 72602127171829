import { ModifierGroup as ModifierGroupType } from '@eo-storefronts/eo-core'
import ModifierGroupMultiChoice
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-multi-choice'
import ModifierGroupSingleChoice
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-single-choice'
import { CartModifierGroupStateInterface } from '~/src/stores/cart'

interface Props {
  group: ModifierGroupType,
  cartModifierGroups: CartModifierGroupStateInterface[],
  onChange(cartModifierGroups: CartModifierGroupStateInterface[]): void,
}

const ModifierGroup = (props: Props) => {
  const ModifierComponent = props.group.isMultipleChoice ? ModifierGroupMultiChoice : ModifierGroupSingleChoice

  return <ModifierComponent {...props} />
}

export default ModifierGroup

