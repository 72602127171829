import { Alert } from '@mui/material'
import { AlertColor } from '@mui/material/Alert/Alert'
import { ReactNode } from 'react'

interface Props {
  children: ReactNode,
  severity?: AlertColor,
}

const ProductAvailabilityAlert = ({ children, severity = 'info' }: Props) => (
  <Alert severity={severity} sx={{ alignItems: 'center' }}>{children}</Alert>
)

export default ProductAvailabilityAlert
