import { ModifierGroup as ModifierGroupType } from '@eo-storefronts/eo-core'
import { Box, Fade } from '@mui/material'
import ModifierGroup from '~/src/components/products/modifier-groups/modifier-group'
import { CartModifierGroupStateInterface } from '~/src/stores/cart'

interface Props {
  groups: ModifierGroupType[],
  cartModifierGroups: CartModifierGroupStateInterface[],
  onChange(cartModifierGroups: CartModifierGroupStateInterface[]): void,
}

const ModifierGroups = ({ groups, cartModifierGroups, onChange }: Props) => {
  if (!groups.length) {
    return null
  }

  return (
    <Fade in={true}>
      <Box>
        {groups
          .map((modifierGroup: ModifierGroupType) => (
            <ModifierGroup
              key={modifierGroup.id}
              group={modifierGroup}
              cartModifierGroups={cartModifierGroups}
              onChange={onChange}
            />
          ))
        }
      </Box>
    </Fade>
  )
}

export default ModifierGroups
