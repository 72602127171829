import { SxProps } from '@mui/material'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import ProductDetailsFooterTemplateServiceInterface
  from '~/src/components/products/product-details/product-details-footer/styles/ProductDetailsFooterTemplateServiceInterface'

export default class ProductDetailsFooterTemplateServiceStyleTwo extends TemplateServiceStyleBase implements ProductDetailsFooterTemplateServiceInterface {
  public getContainerSx(): SxProps {
    return {
      display: 'grid',
      gridTemplateColumns: 'auto',
      gridTemplateRows: 'repeat(3, auto)',
      gridTemplateAreas: '"quantity" "unit-product-price" "button"',
      gap: 2
    }
  }

  public getQuantitySx(): SxProps {
    return {
      gridArea: 'quantity',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }

  public displayUnitProductPrice(): boolean {
    return true
  }

  public getAddToCartSx(): SxProps {
    return {
      gridArea: 'button',
      mt: 3,
      width: '100%',
      [this.muiTheme.breakpoints.only('xs')]: {
        mx: 2,
        position: 'fixed',
        width: `calc(100vw - ${this.muiTheme.spacing(4)})`,
        zIndex: 10,
        bottom: 'calc(20px + var(--eo-safe-area-bottom))'
      }
    }
  }
}
