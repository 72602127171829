import ModifierMediaTemplateServiceInterface
  from '~/src/components/products/modifier-groups/modifier-group/modifier/modifier-media/styles/ModifierMediaTemplateServiceInterface'
import ModifierMediaTemplateServiceStyleOne
  from '~/src/components/products/modifier-groups/modifier-group/modifier/modifier-media/styles/ModifierMediaTemplateServiceStyleOne'
import ModifierMediaTemplateServiceStyleTwo
  from '~/src/components/products/modifier-groups/modifier-group/modifier/modifier-media/styles/ModifierMediaTemplateServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getModifierMediaTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): ModifierMediaTemplateServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new ModifierMediaTemplateServiceStyleTwo(muiTheme)
    default:
      return new ModifierMediaTemplateServiceStyleOne(muiTheme)
  }
}

export default getModifierMediaTemplateService
