import { SxProps } from '@mui/material'
import SingleChoiceModifiersContainerTemplateServiceInterface
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-single-choice/single-choice-modifiers-container/styles/SingleChoiceModifiersContainerTemplateServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class SingleChoiceModifiersContainerTemplateServiceStyleOne extends TemplateServiceStyleBase implements SingleChoiceModifiersContainerTemplateServiceInterface {
  public getContainerSx(): SxProps {
    return { }
  }
}
