import { Allergen } from '@eo-storefronts/eo-core'
import { Box, Tooltip } from '@mui/material'

interface Props {
  allergen: Allergen,
}

const AllergenItem = ({ allergen }: Props) => (
  <Tooltip title={allergen.name}>
    <Box
      component='img'
      sx={{
        maxWidth: '25px',
        maxHeight: '25px'
      }}
      src={allergen.image}
      key={allergen.id}
      alt={`allergen-${allergen.name}`}
    />
  </Tooltip>
)

export default AllergenItem
