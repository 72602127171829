import { Product } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import { useEffect } from 'react'
import Card from '~/src/components/mui-wrappers/Card'
import useProductDetailsTemplateService
  from '~/src/components/products/product-details/styles/useProductDetailsTemplateService'
import ProductAvailabilityAlerts from '~/src/components/products/ProductAvailabilityAlerts'
import ProductDetailsFooter from '~/src/components/products/product-details/product-details-footer'
import ProductDetailsInfoContainer from '~/src/components/products/product-details/product-details-info-container'
import useLogViewItem from '~/src/hooks/analytics/ecommerce/useLogViewItem'
import useGetFooterOffset from '~/src/hooks/layout/useGetFooterOffset'
import { useSetEoState } from '~/src/hooks/useEoState'
import { CART_MODIFIER_GROUP_STATE } from '~/src/stores/cart'
import { PRODUCT_COMMENT_STATE } from '~/src/stores/product-comment'
import ProductMaxQuantityAlert from '~/src/components/products/ProductMaxQuantityAlert'
import ProductDetailsImg from '~/src/components/products/product-details/product-details-img'

interface Props {
  product: Product,
}

const ProductDetails = ({ product }: Props) => {
  const { log: logViewItem } = useLogViewItem()
  const { offset: footerOffset } = useGetFooterOffset()
  const styles = useProductDetailsTemplateService()
  const setCartModifierGroups = useSetEoState(CART_MODIFIER_GROUP_STATE)
  const setProductComment = useSetEoState(PRODUCT_COMMENT_STATE)

  useEffect(() => {
    logViewItem(product)

    return () => {
      setCartModifierGroups([])
      setProductComment('')
    }
  }, [])

  return (
    <Card
      id='product_details'
      sx={{
        p: 0
      }}
      elevation={styles.getCardElevation()}
    >
      <ProductAvailabilityAlerts product={product} />
      <ProductMaxQuantityAlert product={product} />
      <Box sx={styles.getContainerSx()}>
        <ProductDetailsImg product={product} />
        <Box sx={styles.getInfoSx()}>
          <ProductDetailsInfoContainer product={product} />
        </Box>
        <Box sx={styles.getFooterSx(footerOffset as string)}>
          <ProductDetailsFooter product={product} />
        </Box>
      </Box>
    </Card>
  )
}

export default ProductDetails
