import { Product } from '@eo-storefronts/eo-core'
import ProductAvailabilityAlert from '~/src/components/products/ProductAvailabilityAlert'
import { useCategoryAvailabilityMessage } from '~/src/hooks/categories/useCategoryAvailabilityMessage'
import { useCategoryAvailable } from '~/src/hooks/categories/useCategoryAvailable'
import { useProductAvailabilityMessage } from '~/src/hooks/products/useProductAvailabilityMessage'
import { useProductAvailableState } from '~/src/hooks/products/useProductAvailableState'
import { useTranslations } from '~/src/hooks/useTranslations'

interface Props {
  product: Product,
}

const ProductAvailabilityAlerts = ({ product }: Props) => {
  const { t } = useTranslations()
  const { state: productAvailableState } = useProductAvailableState(product)
  const { available: categoryAvailable } = useCategoryAvailable(product.categoryId)
  const { message: categoryAvailabilityMessage } = useCategoryAvailabilityMessage(product.categoryId)
  const { nextAvailability: productAvailabilityMessage } = useProductAvailabilityMessage(product)

  return (
    <>
      {!categoryAvailable && <ProductAvailabilityAlert>{categoryAvailabilityMessage}</ProductAvailabilityAlert>}
      {!productAvailableState.inStock &&
          <ProductAvailabilityAlert>{t('product.out_of_stock')}</ProductAvailabilityAlert>}
      {productAvailableState.snoozed && (
        <ProductAvailabilityAlert>{productAvailabilityMessage}</ProductAvailabilityAlert>
      )}
    </>
  )
}

export default ProductAvailabilityAlerts
