import { SxProps } from '@mui/material'
import ModifierMediaTemplateServiceInterface
  from '~/src/components/products/modifier-groups/modifier-group/modifier/modifier-media/styles/ModifierMediaTemplateServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class ModifierMediaTemplateServiceStyleTwo extends TemplateServiceStyleBase implements ModifierMediaTemplateServiceInterface {
  public getContainerSx(rounded: boolean): SxProps {
    return {
      height: '4em',
      width: '4em',
      borderRadius: rounded ? '50%' : 'var(--btn-border-radius)'
    }
  }
}
