import { useContext } from 'react'
import PrimaryButton from '~/src/components/mui-wrappers/buttons/PrimaryButton'
import ResponsiveDialogContext, {
  ResponsiveDialogContextInterface
} from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'
import { useTranslations } from '~/src/hooks/useTranslations'

const ModifierEditSave = () => {
  const { t } = useTranslations()
  const responsiveDialogContext = useContext<ResponsiveDialogContextInterface>(ResponsiveDialogContext)

  return (
    <PrimaryButton
      onClick={responsiveDialogContext.toggle}
      sx={{
        px: 2,
        py: 1
      }}
    >
      {t('actions.save')}
    </PrimaryButton>
  )
}

export default ModifierEditSave
