import { SxProps } from '@mui/material'
import ModifierGroupTitleTemplateServiceInterface
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-title/styles/ModifierGroupTitleTemplateServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class ModifierGroupTitleTemplateServiceStyleOne extends TemplateServiceStyleBase implements ModifierGroupTitleTemplateServiceInterface {
  public getContainerSx(): SxProps {
    return {
      display: 'flex',
      p: 2,
      gap: 2,
      alignItems: 'center',
      justifyContent: 'flex-start'
    }
  }
}
