import { SxProps } from '@mui/material'
import SingleChoiceModifierTemplateServiceInterface
  from '~/src/components/products/modifier-groups/modifier-group/modifier-group-single-choice/single-choice-modifier/styles/SingleChoiceModifierTemplateServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class SingleChoiceModifierTemplateTwoService extends TemplateServiceStyleBase implements SingleChoiceModifierTemplateServiceInterface {
  public getContainerSx(): SxProps {
    return {
      [this.muiTheme.breakpoints.down('md')]: {
        flexShrink: 0
      }
    }
  }

  public getFormControlSx(): SxProps {
    return {
      height: '100%',
      width: '100%'
    }
  }

  public getFormControlLabelSx(available: boolean, selected: boolean): SxProps {
    return {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: 'tab.main',
      color: !selected ? 'tab.contrastText' : 'primary.main',
      borderColor: !selected ? 'tab.main' : 'primary.main',
      borderRadius: this.muiTheme.shape.borderRadius,
      borderWidth: '1px',
      borderStyle: 'solid',
      px: 2,
      py: 1,
      height: '100%',
      width: '100%',
      filter: !available ? 'grayscale(1)' : undefined
    }
  }

  public getRadioSx(): SxProps {
    return {
      visibility: 'hidden',
      p: 0,
      width: 0,
      height: 0
    }
  }
}
