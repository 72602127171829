import ModifierLabelTemplateServiceInterface
  from '~/src/components/products/modifier-groups/modifier-group/modifier/modifier-label/styles/ModifierLabelTemplateServiceInterface'
import ModifierLabelTemplateServiceStyleOne
  from '~/src/components/products/modifier-groups/modifier-group/modifier/modifier-label/styles/ModifierLabelTemplateServiceStyleOne'
import ModifierLabelTemplateServiceStyleTwo
  from '~/src/components/products/modifier-groups/modifier-group/modifier/modifier-label/styles/ModifierLabelTemplateServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getModifierLabelTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): ModifierLabelTemplateServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new ModifierLabelTemplateServiceStyleTwo(muiTheme)
    default:
      return new ModifierLabelTemplateServiceStyleOne(muiTheme)
  }
}

export default getModifierLabelTemplateService
