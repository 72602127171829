import { Product } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import AllergenList from '~/src/components/products/allergens/allergen-list'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useEoValue } from '~/src/hooks/useEoState'
import { LANGUAGE_STATE } from '~/src/stores/lang'
import ProductTitle from '~/src/components/products/ProductTitle'
import ProductDetailsDescription from '~/src/components/products/product-details/product-details-description'
import ProductDetailsInfo from '~/src/components/products/product-details/product-details-info'

interface Props {
  product: Product,
}

const ProductDetailsInfoContainer = ({ product }: Props) => {
  const muiTheme = useCustomMuiTheme()
  const lang = useEoValue(LANGUAGE_STATE)

  return (
    <>
      <Box
        className='product-detail__title_container'
        sx={{
          width: '100%',
          [muiTheme.breakpoints.down('md')]: {
            px: 2,
            position: 'sticky',
            top: '-1px',
            pb: 2,
            pt: muiTheme.spacing(2),
            backgroundColor: 'secondary.main',
            color: 'secondary.contrastText',
            zIndex: 2
          }
        }}
      >
        <ProductTitle product={product} />
      </Box>
      <AllergenList allergens={product.allergens} />
      <ProductDetailsDescription description={product.description[lang] || ''} />
      <ProductDetailsInfo product={product} />
    </>
  )
}

export default ProductDetailsInfoContainer
